import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Blocks from '../components/blocks';
import Navigation from '../components/navigation';
import Header from '../components/header';
import Footer from '../components/footer';
import { SocialBar, SocialFooterBar } from '../components/social';

export const query = graphql`
  query LandingPageTemplateQuery($id: String!) {
    site {
      siteMetadata {
        title
        description
        author
      }
    }

    page: contentfulLandingPage(id: { eq: $id }) {
      id
      title
      slug
      blocks: contentBlocks {
        type: __typename
        ...BlockCopyFields
        ...BlockHeroFields
        ...BlockFeaturesFields
        ...BlockIntroFields
        ...BlockShareFields
        ...BlockDonateFields
        ...BlockDonateCtaFields
        ...BlockPetitionFields
        ...BlockMailingListFields
        ...BlockCarouselFields
        ...BlockFormFields
        ...BlockMapFields
      }
    }

    navigation: contentfulNavigation(title: { regex: "/^Header >*/" }) {
      id
      title
      items: navigationItems {
        id
        title
        pageLink {
          ... on ContentfulLandingPage {
            slug
          }
          ... on ContentfulCampaignPage {
            slug
          }
        }
        items: navigationItems {
          id
          title
          pageLink {
            ... on ContentfulLandingPage {
              slug
            }
            ... on ContentfulCampaignPage {
              slug
            }
          }
        }
      }
    }

    footer: contentfulFooter(title: { regex: "/^Landing Page > Footer/" }) {
      id
      title
      blocks: contentBlocks {
        type: __typename
        ...BlockCopyFields
        ...BlockMailingListFields
      }
    }
  }
`;

const StyledNavigation = styled(Navigation)``;

const LandingPageTemplate = ({ data }) => {
  const { site, page, navigation, footer } = data;
  const { title, slug, blocks } = page;

  const currentPath =
    typeof window !== 'undefined' ? window.location.pathname : '';

  const pageHeader = (
    <Header siteTitle={site.siteMetadata.title}>
      <SEO title={title} slug={slug} />
      <StyledNavigation items={navigation.items} />
    </Header>
  );

  const pageFooter = (
    <Footer renderDonateCta={currentPath !== '/doneer'}>
      <Blocks blocks={footer.blocks || []} />
    </Footer>
  );

  return (
    <Layout header={pageHeader} footer={pageFooter}>
      <SocialBar />
      <Blocks blocks={blocks || []} />
      <SocialFooterBar />
    </Layout>
  );
};

LandingPageTemplate.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape.isRequired,
    page: PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      blocks: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired
        })
      )
    }),
    navigation: PropTypes.shape.isRequired,
    footer: PropTypes.shape({
      title: PropTypes.string.isRequired,
      blocks: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired
        })
      )
    })
  }).isRequired
};

export default LandingPageTemplate;
