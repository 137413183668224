import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import {
  typography, space, layout, color, flexbox,
} from 'styled-system';
import StyledBurger from './burger';

const StyledBurgerMenu = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};

  ${({ theme }) => theme.media.tablet`
    display: block;
  `}
`;

const StyledNavigation = styled.nav`
  ${flexbox}
  ${typography}
  ${space}
  ${layout}
  ${color}

  ${({ theme }) => theme.media.tablet`
    padding: 1.5rem;
  `}

  ${StyledBurger} {
    position: absolute;
    top: 1.25rem;
    right: 1rem;

    ${({ theme }) => theme.media.tablet`
      display: none;
    `}
  }

  ${StyledBurgerMenu} {
    
    position: absolute;
    z-index: 10;
    width: 100%;
    left: 0;
    padding: 1rem;
    background-color: white;
    box-shadow: 0 12px 12px rgba(0, 0, 0, .2);
    
    ${({ theme }) => theme.media.tablet`
      position: static;
      box-shadow: none;
      padding: 0;
      width: auto;
    `}

    > ul {
      position: relative;
      padding: 0;
      margin: 0;
      list-style: none;
      font-weight: 700;
      text-align: left;

      ${({ theme }) => theme.media.tablet`
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      `}

      > li {

        a {
          color: inherit;
          text-decoration: none;
          
          &:hover {
            color: ${(props) => props.theme.colors.orange};
          }
        }

        > a {
          display: block;
          padding: .5rem;

          ${({ theme }) => theme.media.tablet`
            padding: .5rem 1.5rem;
            text-align: center;
            border-left: 1px solid ${(props) => props.theme.colors.grey.light};
          `}
        }

        &:first-child {
          a {
            border-left: none;
          }
        }

        > a[href='/doneer'] {
          border-left: none;
          color: ${(props) => props.theme.colors.white};
          background-color: ${(props) => props.theme.colors.orange};
        }
        
        > ul {
          margin: 0;
          padding: 0;
          font-size: 0.9375rem;
          list-style: none;
          line-height: 1.5; 
           
          > li a {
            display: block;
            padding: .5rem 1rem;
          }
        }

        ${({ theme }) => theme.media.tablet`
          > a[href='/doneer'] {
            margin-left: 2rem;
          }

          > ul {
            display: none;
            position: absolute;
            right: 0;
            z-index: 10;
            width: 100%;
            padding: .5rem 1.5rem;
            box-shadow: 0 4px 12px rgba(0, 0, 0, .2);
            background-color: ${(props) => props.theme.colors.transparent.white};
            color: ${(props) => props.theme.colors.grey.dark};
          }

          &:hover {
            > ul {
              display: block;
            }
          }
        `}
      }
    }
  }
`;

const Navigation = ({ className, items }) => {
  const [navigationIsVisible, setNavigationIsVisible] = useState(false);

  return (
    <StyledNavigation className={className}>
      <StyledBurger
        name="toggleMenu"
        aria-label="Toggle navigatie"
        onClick={() => setNavigationIsVisible(!navigationIsVisible)}
        open={navigationIsVisible}
      >
        <div />
        <div />
        <div />
      </StyledBurger>
      <StyledBurgerMenu open={navigationIsVisible}>
        <NavigationMenu items={items} />
      </StyledBurgerMenu>
    </StyledNavigation>
  );
};

const NavigationMenu = ({ items }) => {
  const navItems = items.map((item) => {
    const key = item.id;

    return (
      <li key={key} className="">
        {item.pageLink && item.pageLink.slug ? (
          <NavigationLink
            name={item.title}
            to={`/${item.pageLink.slug}`}
          />
        ) : (
          <a href="#fixme">
            {item.title}
          </a>
        )}
        {item.items && !!item.items.length && <NavigationMenu items={item.items} />}
      </li>
    );
  });

  return (
    <ul className="">
      {navItems}
    </ul>
  );
};

Navigation.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    pageLink: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }),
  })).isRequired,
};

NavigationMenu.propTypes = Navigation.propTypes;

const NavigationLink = ({ name, to }) => (
  <Link
    to={to}
  >
    <span>{name}</span>
  </Link>
);

NavigationLink.propTypes = {
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default Navigation;
